import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {
  StrategicObjectiveDTO,
  StrategicObjectiveFormDTO,
} from '../dto/strategic-objective.dto';
import { BaseService } from '../../../../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class StrategicObjectiveService extends BaseService<
  StrategicObjectiveDTO,
  StrategicObjectiveFormDTO
> {
  protected apiUrl = `${environment.apiUrl}/strategic-objectives`;

  constructor(http: HttpClient) {
    super(http);
  }
}
